import React from 'react';
import {
  Box,
  CardContent,
  Container,
  Typography
} from '@material-ui/core';
import hero from '../../static/Registro/hero_register2.svg';
import logo from '../../static/Registro/cfl_white_logo.png';
import balls from '../../static/Registro/balls.png';
import { Helmet } from "react-helmet";
import Layout from "../components/layout"
import RegistroSolicitudCredito from '../components/Register/RegistroSolicitudCredito';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  text: {
    color: "black",
    color: "white",
    fontSize: "3rem",
    width: "45vw",
    fontWeight: "600",
    padding: "0 5vw",
  },
  box: {
    alignItems: 'center',
    backgroundColor: 'background.paper',
    display: 'flex',
    maxHeight: '100%',
    px: 3,
    py: '80px',
    padding: '0px',
    paddingTop: '120px'
  }
}))

export default function Registro() {
  const classes = useStyles();
  return (
    <Layout>
      <Helmet>
        <title>Register </title>
      </Helmet>
      <Box className={classes.box}
      >
        <Container style={{ paddingLeft: '0px', paddingBottom: '0px', width: '43vw' }}>
          <Box sx={{
            width: 'auto',
            height: '100vh',
            backgroundImage: `url(${hero})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'bottom right',
          }}
          >
            <Box sx={{ margin: '0 auto 0 6vw', p: '5vh 0' }}>
              <img
                src={logo}
                alt="logo CFL"
                width="87px"
              />
            </Box>
            <Box sx={{ padding: '20vh 0' }}>
              <Box sx={{ margin: '0 auto 0 5vw' }}>
                <img
                  src={balls}
                  alt="balls"
                  width="87px"
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography className={classes.text}>
                  Obtén el financimiento para alcanzar tus metas.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
        <Container sx={{ maxWidth: '60vw', height: '100vh' }}>
          <Box>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 600,
                minWidth: 400,
                margin: 'calc((100vh - 750px)/2) auto',
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                <RegistroSolicitudCredito />
              </Box>
            </CardContent>
          </Box>
        </Container>
      </Box>
    </Layout>
  );
};
