export const RequiredField = "Este campo es obligatorio"

export const RegisterValidations = {
  phone: {
    regex: /^(\+?\(?[0-9]{0,3}\)?|[0-9]?)[0-9]{1,10}[0-9]?$/,
    maxLen: 10,
    errorMessageRegex: "Formato de teléfono incorrecto",
  },
  email: {
    maxLen: 250,
  },
  capital: {
    regex: /^\$?(\d{1,3},?(\d{3},?)*\d{3}(.\d{0,3})?|\d{1,3}(.\d{2})?)$/,
    maxLen: 10,
    amount: 13000000,
  },
}
