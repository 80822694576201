import React, { useState, useEffect } from "react"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { TextField, Button, Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import successSvg from "./icons/recover-success-check.svg"
import { RegisterValidations, RequiredField } from "../../constants/Register"
import { NumericFormat } from "react-number-format"

const useStyles = makeStyles(theme => ({
  buttonR: {
    borderRadius: "2px",
    margin: theme.spacing(6, 0),
    color: theme.palette.common.white,
    "&:hover": {
      color: theme.palette.common.black,
      background: theme.palette.common.white,
    },
  },
}))

const validationSchema = (valueUDIBanxico) => {
  let pesoMxLocale = Intl.NumberFormat("es-MX", { style: "currency", "currency": "MXN" });
  return Yup.object().shape({
    nombre: Yup.string()
      .required("Este campo es obligatorio")
      .max(250, "Excede el límite de caracteres (250)"),
    celular: Yup.string()
      .required(RequiredField)
      .matches(
        RegisterValidations.phone.regex,
        RegisterValidations.phone.errorMessageRegex
      )
      .max(10, "Formato de teléfono incorrecto")
      .min(10, "El minimo de caracteres es 10"),
    email: Yup.string()
      .max(250, "Excede el límite de caracteres (250)")
      .email("Formato de correo incorrecto")
      .required("Este campo es obligatorio"),
    capital: Yup.number()
      .required("Este campo es obligatorio")
      .test(
        "moreThan",
        `El monto máximo permitido es ${pesoMxLocale.format(valueUDIBanxico)} moneda nacional`,
        value => value <= valueUDIBanxico
      ),
  })
}

const RegistroSolicitudCredito = () => {
  const [formatedCapital, setFormatedCapital] = useState("")
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(true)
  const [valueUDIBanxico, setValueUDIBanxico] = useState(0)
  const classes = useStyles()

  const formatCurrency = value => {
    if (isNaN(value)) return ""
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(value)
  }
  // const parseCurrency = (value) => {
  //   const cleanedValue = value.replace(/[^0-9.]/g, '');
  //   return parseFloat(cleanedValue);
  // };

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    const formDatab = new FormData()
    for (const key in values) {
      if (key === "capital") {
        values[key] = formatedCapital
      }
      formDatab.append(key, values[key])
    }
    fetch(`${process.env.GATSBY_APP_DATA_SCRIPT}`, {
      method: "POST",
      body: formDatab,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Error al enviar los datos")
        }
        return response.json()
      })
      .then(data => {
        if (data.status == "success") {
          setShowSuccessModal(true)
        }

        resetForm()
      })
      .catch(error => {
        console.error("Error:", error)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  async function getUDIfromBanxico () {
    const date = new Date();
    date.setDate(1);
    date.setHours(-1);
    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "2-digit" });
    const day = date.toLocaleString("default", { day: "2-digit" });  
    const formattedDate = `${year}-${month}-${day}`;
    fetch(`${process.env.GATSBY_API_UDI_BANXICO}/${formattedDate}/${formattedDate}?token=${process.env.GATSBY_BANXICO_BMX_TOKEN}`, {
      method: "GET",
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Error al enviar los datos")
        }
        return response.json()
      })
      .then(data => {
        setValueUDIBanxico(Number(data.bmx.series[0].datos[0].dato) * Number(process.env.GATSBY_VALUE_UDIS));
      })
      .catch(error => {
        console.error("Error:", error)
      })
  }

  useEffect(() => {
    getUDIfromBanxico();
}, [])

  return (
    <>
      {showSuccessModal ? (
        <Box display="flex" justifyContent="center">
          <div
            className="modal"
            style={{
              border: "1px solid black",
              paddingLeft: "20px",
              paddingRight: "20px",
              borderRadius: "10px",
              width: "max-content",
              paddingBottom: "5rem",
            }}
          >
            <div className="modal-content" style={{ position: "relative" }}>
              <button
                onClick={() => setShowSuccessModal(false)}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "-10px",
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                X
              </button>
              <Box mb="33px" pt="5rem">
                <img
                  src={successSvg}
                  alt="Success"
                  style={{ display: "block", margin: "0 auto" }}
                />
              </Box>
              <h2
                style={{ color: "rgb(23 241 233 / 87%)", textAlign: "center" }}
              >
                Tu solicitud ha sido enviada.
              </h2>
              <p style={{ textAlign: "center" }}>
                Nuestros asesores se pondrán en contacto contigo.
              </p>
            </div>
          </div>
        </Box>
      ) : (
        <Formik
          initialValues={{ nombre: "", celular: "", email: "", capital: "" }}
          validationSchema={validationSchema(valueUDIBanxico)}
          onSubmit={onSubmit}
        >
          {({
            isSubmitting,
            isValid,
            errors,
            values,
            handleChange,
            setFieldValue,
            setFieldError,
            setErrors,
          }) => (
            <div>
              <Box mt="50px">
                <Typography color="textPrimary" gutterBottom variant="h3">
                  Solicita un crédito
                </Typography>
              </Box>
              <Form>
                <div>
                  <Field
                    name="nombre"
                    as={TextField}
                    label="Nombre Completo"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={!!errors.nombre}
                    helperText={errors.nombre}
                    // required
                  />
                  {/*<ErrorMessage name="nombre" />*/}
                </div>
                <div>
                  <Field
                    name="celular"
                    as={TextField}
                    label="Celular"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    // required
                    value={values.celular}
                    error={!!errors.celular}
                    helperText={errors.celular}
                    onChange={e => {
                      const {
                        target: { value },
                      } = e
                      if (value.length > RegisterValidations.phone.maxLen) {
                        return
                      }
                      if (value === "") {
                        setFieldValue("celular", "")
                        setFieldError("celular", RequiredField)
                        return
                      }
                      if (!RegisterValidations.phone.regex.test(value)) {
                        return
                      }
                      setFieldValue("celular", value)
                    }}
                  />
                  {/* <ErrorMessage name="celular" /> */}
                </div>
                <div>
                  <Field
                    name="email"
                    as={TextField}
                    type="email"
                    label="Correo Electrónico"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={values.email}
                    error={!!errors.email}
                    helperText={errors.email}
                    onChange={e => {
                      const {
                        target: { value },
                      } = e
                      handleChange(e)
                    }}
                    // required
                  />
                  {/* <ErrorMessage name="email" /> */}
                </div>
                <div>
                  <NumericFormat
                    name="capital"
                    margin="normal"
                    variant="outlined"
                    type="text"
                    fullWidth
                    customInput={TextField}
                    label="Capital Requerido"
                    value={values.capital}
                    error={!!errors.capital}
                    helperText={errors.capital}
                    thousandSeparator
                    prefix="$"
                    fixedDecimalScale
                    decimalScale={2}
                    onValueChange={({ floatValue, formattedValue }) => {
                      setFormatedCapital(formattedValue)
                      setFieldValue("capital", floatValue)
                    }}
                  />
                  {/* <ErrorMessage name="capital" /> */}
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  color="black"
                  value={values.capital}
                  className={classes.buttonR}
                  size="large"
                  fullWidth
                  disabled={isSubmitting || !isValid}
                >
                  Enviar
                </Button>
              </Form>
            </div>
          )}
        </Formik>
      )}
    </>
  )
}

export default RegistroSolicitudCredito
